.fake-modal {
  &.modal {
    position: relative;
    display: block;
  }

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    box-shadow: none;
  }
}
