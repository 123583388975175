body:not(.home) app-layout-site {
  .site-sidebar {
    left: 0;

    @include media-breakpoint-only(xs) {
      position: relative;
      display: block;
      width: initial;
      background-image: none;
    }
  }

  main {
    margin-left: 250px;
    transition: margin-left 0.3s;

    @include media-breakpoint-only(xs) {
      margin-left: 0 !important;
    }
  }

  .main-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}
