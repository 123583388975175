// NOTE: two variants of code blocks.  First, free-standing ones
.code-block {
  position: relative;

  @import 'highlight.js/scss/nnfx-light.scss';

  // .bgdark & {
  //   @import 'highlight.js/scss/nnfx-dark.scss';
  // }

  pre[class*='language-'] {
    padding: 0.75rem;
    margin-bottom: 0;

    // .bgdark & {
    //   scrollbar-color: var(--gray500) var(--gray600);
    // }
  }

  //   &.code-block-simple {
  //     margin-bottom: 8px;
  //     border: 1px solid var(--pbds-border-color);

  //     pre[class*='language-'] {
  //       padding: 0.75rem 0 0.75rem 0.75rem;
  //     }

  //     .code-copy {
  //       right: 1.25rem;
  //     }
  //   }

  .code-copy {
    position: absolute;
    top: 0.5em;
    right: 0;
    padding: 0.5em;
    cursor: pointer;
    font-size: 0.675rem;
    z-index: 2;
  }

  .tabset-codeblock {
    &.nav-tabs {
      border-bottom: 1px solid var(--pbds-border-color);

      // .bg-dark & {
      //   border-bottom: 2px solid var(--divider_color);
      // }

      .tab-codeblock {
        a,
        a.nav-link {
          padding: 0.5rem 1rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          color: var(--text);
          font-size: var(--pbds-font-size-sm);

          // .bgdark & {
          //   background-color: var(--dark_bg);
          // }

          &:hover {
            color: var(--pbds-secondary);
          }

          &.active {
            border: 1px solid var(--pbds-border-color);
            border-bottom: 1px solid var(--pbds-white);
            color: var(--pbds-body-color);

            // .bgdark & {
            //   background-color: var(--dark_input_bg);
            //   color: var(--white);
            // }
          }
        }
      }
    }
  }

  .tab-content {
    margin-bottom: 1rem;

    .tab-pane.active {
      padding: 0;
      border: $border-width solid var(--pbds-border-color);
      border-top: 0;
      border-bottom: 0;

      // .bgdark & {
      //   background-color: var(--dark_input_bg) !important;
      // }
    }
  }
}

//NOTE: second variant is tabs inside a "component example" box
.component-example {
  margin-bottom: 2rem;
  border: var(--pbds-border-width) solid var(--pbds-border-color);

  figure {
    padding: $spacer;
    margin: 0;
  }

  .code-block {
    .tab-content {
      margin-bottom: 0;
      border: 0;

      .tab-pane.active {
        border: 0;
      }
    }

    ul.tabset-codeblock {
      li.tab-codeblock:first-child {
        a,
        a.nav-link {
          border-left: 0;
        }
      }
    }
  }
}

// this class is for code displayed outside the code snippet tabs
.code-example {
  background-color: var(--pbds-white);
  border: 1px solid var(--pbds-border-color);
  border-radius: var(--pbds-border-radius);
  padding: 0.5rem;
  margin: 0.25rem 0 1rem;
  color: var(--pbds-info);
  font-family: var(--pbds-font-monospace);
  font-variant-ligatures: contextual;

  // .bgdark & {
  //   background-color: var(--dark_input_bg);
  //   color: #e1e1e1;
  //   border-color: var(--dark_input_border);
  // }
}

// this class is for code displayed outside the code snippet tabs
.text-example {
  // background-color: #fff;
  border: 1px solid var(--pbds-border-color);
  border-radius: var(--pbds-border-radius);
  padding: 0.5rem;
  margin: 0.25rem 0 1rem;

  p {
    margin-bottom: 0;
  }
}

// // code snippets
// pre code,
// code {
//   color: #0072b8;

//   .bgdark & {
//     color: #e1e1e1;
//   }

//   // font-size: 0.8rem !important;
//   .token.comment {
//     font-style: italic;
//   }
// }

// .component-example + .alert {
//   margin-top: -1.5rem;
//   margin-bottom: 2rem;
// }

// // note, not figcaption - for captions below some images
// figure {
//   .caption {
//     margin-top: 0.5rem;
//     font-style: italic;
//   }
// }
