body.home {
  background-color: #202d51;
  color: #fff;

  .hero-main {
    padding-top: 70px;
    background-image: linear-gradient(
      135deg,
      rgb(0, 114, 184, 0.7) 0%,
      rgb(49, 65, 131, 0.7) 50%,
      rgb(160, 63, 155, 0.7) 100%
    );

    .site-search {
      .algolia-typeahead {
        background-color: #fff;

        .pbi-search {
          color: var(--pbds-body-color) !important;
          font-size: 1.5rem;
        }

        input {
          color: var(--pbds-body-color) !important;
          box-shadow: none !important;
        }
      }
    }

    .hero-waves {
      display: block;
      margin-top: 60px;
      width: 100%;
      height: 40px;
      color: #202d51;
    }

    .wave1 use {
      animation: move-forever1 10s linear infinite;
      animation-delay: -2s;
    }

    .wave2 use {
      animation: move-forever2 8s linear infinite;
      animation-delay: -2s;
    }

    .wave3 use {
      animation: move-forever3 6s linear infinite;
      animation-delay: -2s;
    }

    @keyframes move-forever1 {
      0% {
        transform: translate(85px, 0%);
      }

      100% {
        transform: translate(-90px, 0%);
      }
    }

    @keyframes move-forever2 {
      0% {
        transform: translate(-90px, 0%);
      }

      100% {
        transform: translate(85px, 0%);
      }
    }

    @keyframes move-forever3 {
      0% {
        transform: translate(-90px, 0%);
      }

      100% {
        transform: translate(85px, 0%);
      }
    }
  }

  .content {
    margin-top: 75px;
  }

  .coach-marks-home {
    margin-top: 12px;
    box-shadow: 0 8px 16px 3px rgba(0, 0, 0, 0.5);

    .shepherd-content .shepherd-text {
      font-size: var(--font_size_base);
      line-height: 1.5;
    }
  }
}
