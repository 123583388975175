.doc-led {
  width: 20px;
  height: 20px;

  &.led-white {
    background-color: rgb(255, 255, 255);
  }

  &.led-blue {
    background-color: rgb(0, 155, 223);
  }

  &.led-red {
    background-color: rgb(253, 38, 64);
  }

  &.led-green {
    background-color: rgb(0, 255, 0);
  }

  &.led-amber {
    background-color: rgb(255, 128, 0);
  }

  &.led-off {
    background-color: rgb(150, 150, 150);
  }
}
