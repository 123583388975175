.doc-hero-gradient {
  background-image: var(--pbds-gradient-classic); // TODO should be generic based on selected theme
  height: 45px;
}

.doc-hero-header {
  background-color: var(--pbds-rich-100);
  padding-left: 1.5rem;

  // .bgdark & {
  //   background-color: var(--dark_bg_accent);
  // }

  .theme-menus {
    // select {
    //   // background-color: var(--pbds-rich-100);

    //   // .bgdark & {
    //   //   background-color: var(--dark_input_bg);
    //   // }
    // }
  }

  // .bgwhite & {
  //   background-color: var(--rich100);

  //   select {
  //     background-color: var(--white);
  //   }
  // }
}
