.scrollspy {
  top: 0;

  a {
    color: var(--pbds-body-color);

    &.active {
      color: var(--pbds-secondary);
      font-weight: 600;
    }
  }

  ul {
    ul {
      li {
        font-size: var(--pbds-font-size-sm);
      }
    }
  }
}

// the main content section on every page on the site
.scrollspy-content {
  padding-bottom: 5rem;

  @include media-breakpoint-up(sm) {
    padding-left: 2.5rem !important;
  }

  section {
    margin-top: 2rem;
    border-top: 1px solid var(--pbds-border-color);

    &:not(:first-of-type) {
      margin-top: 3rem;
    }

    .tab-content-noborder {
      .tab-pane {
        border-bottom: none;
      }
    }
  }
}
