// see dataviz kitchensink page for demo, not used in products
.weather-alert.pbds-chart.pbds-chart-choropleth-map {
  background-color: #4e4e4e; // TODO

  .mesh {
    display: none;
  }

  .feature {
    fill: #717171 !important; // TODO
    stroke: #717171 !important; // TODO
  }

  .hasData {
    fill: #9b9b9b !important; // TODO
    stroke: #9b9b9b !important; // TODO
  }
}
