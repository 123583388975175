$sidebar-width: 250px;

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }

  70% {
    box-shadow: 0 0 5px 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.site-sidebar {
  width: $sidebar-width;
  background-color: var(--pbds-gray-900);

  @at-root {
    // home only styles
    .docs-sidenav {
      background-color: var(--pbds-gray-900);

      &.offcanvas-start {
        width: $sidebar-width;
        border-right: 0;

        &:focus,
        &:focus-visible {
          outline: none;
          border-right: 0;
          box-shadow: none;
        }

        .offcanvas-header {
          padding-top: 0.5rem;
          padding-bottom: 0;

          .btn {
            color: var(--pbds-white);
            font-size: 1rem;

            &:focus {
              outline-offset: -1px;
            }
          }
        }

        .offcanvas-body {
          padding: 0;

          &::-webkit-scrollbar {
            width: 10px; // TODO
            height: 10px; // TODO
            background-color: var(--pbds-body-color);
          }

          &::-webkit-scrollbar-thumb {
            background: var(--pbds-gray-500);
            border-radius: 0.5rem;
            box-shadow: var(--pbds-primary) 0 0 0 1px inset;
          }

          .site-sidebar {
            position: relative !important;

            .sidebar-header {
              display: none;
            }
          }
        }
      }
    }
  }

  .site-navigation {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px; // TODO
      height: 10px; // TODO
      background-color: var(--pbds-body-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--pbds-gray-500);
      border-radius: 0.5rem;
      box-shadow: var(--pbds-primary) 0 0 0 1px inset;
    }

    .nav {
      flex-flow: column nowrap;

      .accordion {
        .accordion-item {
          border: 0;

          .accordion-header {
            .accordion-button {
              display: flex;
              justify-content: space-between;
              color: var(--pbds-gray-300);
              box-shadow: none;

              &:hover {
                color: var(--pbds-white);
              }

              &::after {
                background-color: var(--pbds-gray-300);
              }
            }
          }

          .accordion-body {
            padding: 0;

            .section-header {
              color: var(--pbds-gray-400);
              font-size: var(--pbds-font-size-sm);
            }

            .list-group {
              margin-bottom: 1rem;
              border-radius: 0;

              a.list-group-item {
                padding: 0.25rem 1rem 0.25rem 2.5rem;
                border: 0;
                border-left: 0.25rem solid transparent;
                border-radius: 0;
                background-color: transparent;
                color: var(--pbds-gray-400);
                font-family: var(--pbds-font-family-regular);
                font-size: var(--pbds-font-size-base);
                font-weight: normal;

                &:hover {
                  background-color: var(--pbds-gray-600);
                  color: var(--pbds-white);
                  text-decoration: none;
                }

                &.active {
                  color: var(--pbds-white);
                  border-color: var(--pbds-white);

                  &:hover {
                    color: var(--pbds-white);
                  }
                }
              }
            }
          }
        }
      }
    }

    .loose-links {
      padding-top: 5px;

      .list-group {
        margin-bottom: 0;

        a.list-group-item {
          padding: 0.5rem 1rem 0.5rem 2rem;
          border: 0;
          background-color: transparent;
          border-radius: 0;
          color: var(--pbds-gray-300);
          font-family: var(--pbds-font-family-regular);
          font-size: var(--pbds-font-size-base);
          font-weight: normal;

          &:hover {
            background-color: var(--pbds-gray-600);
            color: var(--pbds-white);
          }

          &.active {
            color: var(--pbds-white);
          }
        }
      }
    }

    .badge-new {
      animation: pulse 3s infinite;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.4); // TODO
      background-color: var(--pbds-success) !important; // TODO
      color: var(--pbds-white);
      font-size: 9px; // TODO
      border-radius: 10rem;
      float: right;
    }

    .section-header .badge-new {
      margin-right: 1.25rem;
    }
  }
}
