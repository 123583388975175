figure {
  margin: 1rem 0;

  figcaption {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    clear: both;
    color: var(--pbds-primary);
    font-style: italic;
    font-family: var(--pbds-font-sans-serif);
    font-size: var(--pbds-font-size-sm);

    // .bgdark & {
    //   color: #6f8af5;
    // }

    // + h2 {
    //   margin-top: 0;
    // }

    // &:first-of-type {
    //   margin-top: 0;
    // }

    // &.ko {
    //   color: #fff;
    // }
  }
}

.component-example {
  figure {
    figcaption {
      margin-top: 0;
    }
  }
}

// // figures and figcaptions

// // code[class*='language-'],
// // pre[class*='language-'] {
// //   font-family: var(--font-family-monospace);
// //   font-size: var(--font_size_sm);
// //   margin: 0;
// //   > code {
// //     box-shadow: none !important;
// //     border: 0;
// //     background-image: none;
// //   }
// // }
// // pre[class*='language-']::before,
// // pre[class*='language-']::after {
// //   box-shadow: 0;
// //   display: none;
// // }

// // plugin-table is applied to the component via hostBinding
// .plugin-table {
//   margin: 1rem 0 2rem;
//   display: block;
//   a {
//     text-decoration: underline;
//   }
// }

// // if one follows a component example it should be closer to the example than the following one
// .component-example + .plugin-table {
//   margin-top: -1.5rem;
//   margin-bottom: 3rem;
// }
