.doc-text-block,
.dtb {
  width: 80%;
  margin-bottom: 1rem;
}

.doc-lede,
.dl {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 16px; // TODO
  line-height: 1.5;

  p {
    font-size: 16px; // TODO
    line-height: 1.5;
  }
}
