.brand-illustrations {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  text-align: center;

  img {
    width: 5rem;
    height: 5rem;
  }

  p {
    margin-top: 0.5rem;
    font-size: var(--pbds-font-size-sm);
  }
}

.icon-grid {
  display: grid;
  margin: 1rem 0;
  grid-auto-rows: 60px;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  .icon-swatch-wrapper {
    display: flex;
    height: 100%;
    align-items: stretch;

    border: 1px solid var(--pbds-border-color);
    border-radius: var(--pbds-border-radius);
    font-size: var(--pbds-font-size-sm);

    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid var(--pbds-border-color);
      background-color: var(--pbds-white);
    }

    &-right {
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.5rem;
      background-color: var(--pbds-white);

      &:hover {
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transform: translate(0, -1px);
        transition:
          transform 0.3s,
          box-shadow 0.3s;
      }
    }

    // .bgdark & {
    //   &-left,
    //   &-right {
    //     background-color: var(--dark_input_bg);
    //   }
    // }

    .icon {
      padding: 0 0.5rem;
      font-size: 1.5rem;
    }

    .icon-name {
      font-size: 0.8rem;
    }

    .icon-code {
      font-family: var(--pbds-font-monospace);
      font-size: 0.625rem;
      margin-top: 0.25rem;
      letter-spacing: -0.05em;
    }
  }
}
