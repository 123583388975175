.site-search {
  .algolia-typeahead {
    background-color: #484d56; // TODO

    &::before {
      .home & {
        color: var(--pbds-body-color);
        font-size: 1.5rem;
      }
    }

    input {
      font-family: var(--pbds-font-sans-serif);

      &:not(:valid) {
        & ~ .search-clear {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }

      .home & {
        background-color: var(--pbds-white);
        font-size: var(--pbds-font-size-lg);
        padding: 0.5rem 1rem 0.5rem 2.5rem;
        min-width: 430px;
        color: var(--pbds-black);
      }
    }

    ngb-typeahead-window {
      width: 100%;
      transform: translateX(0) !important;
      transform: translateY(30px) !important;

      .dropdown-item {
        overflow: hidden;
        padding: 0.25rem;
        text-overflow: ellipsis;

        &.active {
          background-color: var(--pbds-primary);
          color: var(--pbds-white);
        }
      }
    }
  }
}
