.demo-image {
  margin: 0 0 1rem 0;

  a {
    position: relative;
    display: inline-block;
    background-color: var(--pbds-body-color);
    cursor: pointer;

    img {
      border: 1px solid var(--pbds-border-color);
      opacity: 1;
      transition: opacity 0.2s;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: var(--pbds-white);
      content: 'View Demo';
      font-family: var(--pbds-font-family-regular);
      font-size: 1.125rem;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        opacity: 0.2;
      }
    }
  }
}
