.mfa-example {
  min-height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  img {
    max-width: 300px;
    height: auto;
  }
}
